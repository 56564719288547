import axios from "axios";
import { getEnv } from "../utils/Helper";
import { use } from "i18next";
const base_url = getEnv("REACT_APP_API_BASE_URL");
const API_token = getEnv("REACT_APP_API_TOKEN");

export const getSchemaAPI = (payload: any) =>
  axios({
    method: "GET",
    url: `${base_url}/assembler/get_element?next_element=${payload["value"]};p_session_navigation_id=${payload["sn_id"]};p_session_navigation_element_id=${payload["sne_id"]};business_unit_code=FLOW_PA;p_traffic_source_code=NORMAL_ENTRY;p_session_navigation_element_attribute_values=null`,
    headers: { "Content-Type": "application/json", Authorization: API_token },
  });

export const getLanguageListAPI = () =>
  axios({
    method: "GET",
    url: `${base_url}/assembler/get_language_list`,
    headers: { "Content-Type": "application/json", Authorization: API_token },
  });

export const getCalculationAPI = (payload: any) =>
  axios({
    method: "POST",
    url: `${base_url}/assembler/calculation?sn_id=${payload.element_data.sn_id};sne_id=${payload.element_data.sne_id}`,
    headers: { "Content-Type": "application/json", Authorization: API_token },
    data: payload.data,
  });

export const getSizingResultsAPI = (payload: any) =>
  axios({
    method: "POST",
    url: `https://flowproductadvisorservices-dev.azurewebsites.net/mmipa/search_sizing_results/`,
    headers: { "Content-Type": "application/json" },
    data: payload,
  });

export const getLeftAnchorDataAPI = (payload: any) =>
  axios({
    method: "GET",
    // url: `${base_url}/assembler/get_elements_list?business_unit_code=${payload.business_unit_code}&p_traffic_source_code=${payload.p_traffic_source_code}&lang=${payload.lang}`,
    url: `${base_url}/assembler/get_elements_list`,
    params: {...payload},
    headers: { "Content-Type": "application/json", Authorization: API_token },
  });

export const getRightAnchorDataAPI = (payload: any) =>
  axios({
    method: "GET",
    // url: `${base_url}/assembler/get_elements_detail?business_unit_code=${payload.business_unit_code}&p_session_navigation_id=${payload.p_session_navigation_id}&p_ne_id=${payload.p_ne_id}&p_request_json=${payload.p_request_json}&lang=${payload.lang}`,
    url: `${base_url}/assembler/get_elements_detail`,
    params: payload,
    headers: { "Content-Type": "application/json", Authorization: API_token },
  });

export const getProductionListAPI = (payload: any) =>
  axios({
    method: "POST",
    url: `${base_url}/assembler/get_product_list_LTA`,
    headers: { "Content-Type": "application/json", Authorization: API_token },
    data: payload,
  });

export const getExtOktaSession = (payload: any) =>
  axios({
    url: "https://accessemr.okta.com/api/v1/sessions/me",
    method: "GET",
    withCredentials: true,
  });

export const getIntOktaSession = (payload: any) =>
  axios({
    url: "https://emerson.okta.com/api/v1/sessions/me",
    method: "GET",
    withCredentials: true,
  });

export const deleteUserOktaSession = (payload: any) =>
  axios({
    url: "https://accessemr.okta.com/api/v1/sessions/me",
    method: "DELETE",
    withCredentials: true,
  });

export const deleteIntUserOktaSession = (payload: any) =>
  axios({
    url: "https://emerson.okta.com/api/v1/sessions/me",
    method: "DELETE",
    withCredentials: true,
  });

export const customAPI = (
  method: string,
  url: string,
  header: any,
  payload: any
) => {
  let obj: any = {
    method: method.toUpperCase(),
    url: `${base_url}${url}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: API_token,
      ...header,
    },
  };
  if (method.toUpperCase() === "POST") {
    obj.data = payload;
  } else if (method.toUpperCase() === "GET") {
    obj.params = payload;
  }
  return axios(obj);
}


export const getAllSizingAPI = (payload: any) => {
  return axios({
    method: "POST",
    // url: `https://spence-sizing-api-dev.azurewebsites.net/v2/user/all-sizings`,
    url: `${base_url}/assembler/spence_sizing`,
    data: {userId: payload},
    headers: { "Content-Type": "application/json", Authorization: API_token },
  });

}

export const fetchReportsApi = (payload: any) => {
  return axios({
    method: "POST",
    url: `${base_url}/assembler/spence_reports`,
    // url: `https://spence-sizing-api-dev.azurewebsites.net/v2/reports`,
    headers: { "Content-Type": "application/json", Authorization: API_token },
    data: payload,
  });
}


export const saveSizingAPI = (payload: any) => {
  return axios({
    method: "POST",
    url: `${base_url}/assembler/spence_save_sizing`,
    // url:"https://spence-sizing-api-dev.azurewebsites.net/v2/user/save-sizing",
    headers: { "Content-Type": "application/json", Authorization: API_token },
    data: payload,
  });
}

// .. not used
export const onFetchSizingAPI = (payload?:any) => 
  axios({
    // url:'https://spence-sizing-api-dev.azurewebsites.net/v1/user/fetch-sizing?id=SPEN0000424',
    url: `${base_url}/assembler/spence_fetch_sizing?id=${payload.id}`,
    method: 'GET',
    headers: { "Content-Type": "application/json", Authorization: API_token },
    withCredentials: false 
  })



  export const fetchUOMData = (payload?:any) => 
    axios({
    url: `${base_url}/assembler/spence_uom`,
    // url:"https://spence-sizing-api-test.azurewebsites.net/v1/uom",
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: API_token },
  });

// we use post method to save the user preference, SPENCE USE PUT METHOD
export const updateUserPreferenceAPI = (payload?: any) =>{
  return axios({
    method: "POST",
    // PUT:  https://spence-sizing-api-dev.azurewebsites.net/v2/user/update-preferences
    url: `${base_url}/assembler/spence_user_preference`,
    headers: { "Content-Type": "application/json", Authorization: API_token },
    data: payload,
  });
}

export const getUserPreferenceAPI = (payload?: any) =>{
  return axios({
    method: "POST",
    // url: `https://spence-sizing-api-dev.azurewebsites.net/v2/login`,
    url: `${base_url}/assembler/spence_login`,
    headers: { "Content-Type": "application/json", Authorization: API_token },
    data: payload,
  });
}


  
