import Constant from "../constants/schemaConstants";

export const getSchemaData = (
  sn_id: string,
  sne_id: string,
  value: string
) => ({
  type: Constant.STORE_SCHEMA,
  sn_id,
  sne_id,
  value,
});

export const getCalculationData = (payload: any) => ({
  type: Constant.STORE_CALCULATION,
  payload,
});

export const getSizingResults = (payload: any) => ({
  type: Constant.STORE_SIZING_RESULTS,
  payload,
});

export const getLeftAnchorData = (payload: any) => ({
  type: Constant.STORE_LEFT_ANCHOR,
  payload,
});

export const getRightAnchorData = (payload: any) => ({
  type: Constant.STORE_RIGHT_ANCHOR,
  payload,
});

export const updateLeftAnchorData = (payload: any) => ({
  type: Constant.STORE_UPDATE_LEFT_ANCHOR,
  payload,
});

export const updateLeftAnchorkeyData = (payload: any) => ({
  type: Constant.STORE_UPDATE_LEFT_KEY_ANCHOR,
  payload,
});

export const updateRightAnchorData = (payload: any) => ({
  type: Constant.STORE_UPDATE_RIGHT_ANCHOR,
  payload,
});

export const updateRightAnchorKeyData = (payload: any) => ({
  type: Constant.STORE_UPDATE_RIGHT_KEY_ANCHOR,
  payload,
});
export const resetRightAnchorValues = (payload?:any) => ({
  type: Constant.RESET_RIGHT_ANCHOR_VALUES,
  payload
});
export const clearRightAnchorData = (payload: any) => ({
  type: Constant.CLEAR_RIGHT_ANCHOR,
  payload,
});

export const getProductionListData = (payload: any) =>({
  type: Constant.STORE_PRODUCTION_LIST,
  payload,
})

export const getLanguageListData = (payload: any) => ({
  type: Constant.STORE_LANGUAGE_LIST,
  payload
});

export const updateCurrentId = (payload: any) => ({
  type: Constant.UPDATE_CURRENT_NEID,
  payload,
});

export const updateUserInfo = (payload: any) => ({
  type: Constant.UPDATE_USER_INFO,
  payload,
});

export const updateSizingInput = (payload: any) => ({
  type: Constant.UPDATE_SIZING_INPUT,
  payload,
});

export const getAllSizing = (payload: any) => {
  return ({
    type: Constant.GET_ALL_SIZING,
    payload,
  });
}

export const updateTopData = (payload: any) => ({
  type: Constant.STORE_UPDATE_TOP_ANCHOR,
  payload,
});


export const getActiveSession = (payload: any) => {
  return ({
    type: Constant.STORE_ACTIVE_SESSION,
    payload,
  });
}

export const deleteActiveSession = (payload: any) => {
  return ({
    type: Constant.DELETE_ACTIVE_SESSION,
    payload,
  });
}

export const updateLoading = (payload: boolean) => {
  return ({
    type: Constant.UPDATE_LOADING,
    payload,
  });
}

export const setReportPayload = (payload: boolean) => {
  return ({
    type: Constant.SET_REPORT_PAYLOAD,
    payload,
  });
}

export const getUOMList = (payload?: any) => ({
  type: Constant.STORE_UOM_DATA,
  payload
});

export const getFetchSizing = (payload?: any) => ({
  type: Constant.GET_FETCH_SIZING,
  payload
});

export const updateRightData = (payload?: any) => ({
  type: Constant.UPDATE_RIGHT_DATA,
  payload
})

export const updateUserPreference = (payload?: any)=> {
  return ({
    type: Constant.UPDATE_USER_PREFERENCE_DATA,
    payload
  })
}

export const updatePreference = (payload?: any)=> {
  return ({
    type: Constant.UPDATE_PREFERENCE_DATA,
    payload
  })
}

export const updateBuCode = (payload?: any)=> ({
  type: Constant.UPDATE_BU_CODE,
  payload
})

export const updateLoginState = (payload?: any)=> ({
  type: Constant.UPDATE_LOGIN_STATE,
  payload
})

export const getUserPreference = (payload?: any)=> ({
  type: Constant.GET_USER_PREFERENCE,
  payload
})

export const updateUserId = (payload?: any)=> ({
  type: Constant.UPDATE_USER_ID,
  payload
})

export const updateSizingId = (payload?: any)=> ({
  type: Constant.UPDATE_EDIT_SIZINGID,
  payload
})

export const updateOrAddOptionsByPreferences = (payload?: any)=> ({
  type: Constant.UPDATE_OPTIONS_BY_PREFERECES,
  payload
})

export const storeDefaultRightAnchor = (payload?: any)=> ({
  type: Constant.STORE_DEFAULT_RIGHT_ANCHOR,
  payload
})

export const updateAlertMessage = (payload?: any)=> ({
  type: Constant.UPDATE_ALERT_MESSAGE,
  payload
})













