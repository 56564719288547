import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Alert } from '@emerson/dynamic-ui-dev';
import { useDispatch, useSelector } from "react-redux";
import store, { RootState } from '../store/Store';
  // minified version is also included
  // import 'react-toastify/dist/ReactToastify.min.css';
import './ToastAlert.css';

const ToastAlert= () => {

    const alertErrorMsg = useSelector((state: RootState) => state.schemaData.alertErrorMsg);

    const dispalyMessage = (data: any) => {
      if(typeof data === 'string') {
        return data;
      } else if(typeof data === 'object') {
        let valueC = "";
        Object.keys(data).map((v: any) => {
          valueC = valueC + v + ": " +  data[v] + "<br/>";
        });
        return valueC;
      }
    }

    const notify = () => toast(
        <Alert 
            containerClass={'containerClass'}
            alertStyle={{"padding": "0 5px"}}
            alertTitle={'Error'}
            alertContent={dispalyMessage(alertErrorMsg)}
            onClose={() =>{}}
            open={true}
            severity="error"
        />
    );

    useEffect(() => {
      if (alertErrorMsg) {
        notify();
      }
    }, [alertErrorMsg]);

    return (
      <div>
        <ToastContainer
            limit={3}
            theme="colored"
            autoClose={5000}
            hideProgressBar
            position="bottom-left"
        />
      </div>
    );
  }

  export default ToastAlert;
